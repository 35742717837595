.busPlanWrap {
    border: 3px solid #c4c8cf;
    border-radius: 10px;
    padding: 1em;
    position: relative;
    display: inline-block;
    width: 100%;
    .seats {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        width: 100%;
        @media (max-width: 767.98px) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .seat-row {
            display: flex;
            justify-content: space-between;
            @media (max-width: 767.98px) {
                flex-direction: column;
                flex-wrap: wrap;
            }
            .seat-item {
                width: 2.5em;
                height: 2.5em;
                margin: .1rem;
                background: #f5f5f5;
                border: 1px solid #c4c8cf;
                color: #6b7c8f;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: not-allowed;
                @media (max-width: 767.98px) {
                    width: 4em;
                    height: 4em;    
                }
                &.plain {
                    border: 0;
                    background: #fff;
                }
                &.active {
                    background: #fff;
                    border: 1px solid #7cb798;
                    color: #7cb798;
                    cursor: pointer;
                    &:hover, &.selected {
                        background: #45c4a0;
                        color: #fff;
                    }
                }
            }
        }
    }
    
    
}